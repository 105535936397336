import { useField } from 'formik';
import { FlexLayout, CheckboxField } from '@gv/triage-components';

import { CallOutcomeType } from 'types';
import { callOutcomeModel } from 'components/cases/form';

export const CallOutcomeCheckboxes = () => {
  const [type] = useField(callOutcomeModel.type);
  const isEmergency = type.value === CallOutcomeType.Emergency;
  const isAppointment = type.value === CallOutcomeType.Appointment;

  return (
    <FlexLayout gap={24} flexDirection="column">
      {(isEmergency ||
        isAppointment ||
        type.value === CallOutcomeType.Behavior ||
        type.value === CallOutcomeType.ShelterClinic ||
        type.value === CallOutcomeType.ReferToGeneralPractice ||
        type.value === CallOutcomeType.ReferToEmergency ||
        type.value === CallOutcomeType.ShelterAdmin) && (
        <>
          <CheckboxField
            name="checkbox1"
            label="I told the pet owner what conditions the pet could have based on the symptoms they described to me (I understand we do not have a VCPR and I cannot give a diagnosis) and explained why the issue requires either an Appointment or an ER visit"
          />
          <CheckboxField
            name="checkbox2"
            label="I explained what tests the Pet O can expect to have done when they arrive at the veterinary practice"
          />
          <CheckboxField
            name="checkbox3"
            label="I recapped my findings with the Pet O and have addressed any other of their potential concerns to the best of my ability"
          />
        </>
      )}
    </FlexLayout>
  );
};
