import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from 'react';
import {
  useAlert,
  Messages,
  useNavigate,
  ChildrenProps,
  ActionQueueType,
} from '@gv/triage-components';

import { api } from 'api';
import { getUser } from 'utils/storage';
import { CommContext } from 'context/comm';
import { endChat } from 'api/subscription';
import { selectAuthUser } from 'store/slices/auth';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { selectClients, useUpdateMutation } from 'store/api/clients';
import { setOnVideoCall, setVideoCallEnded } from 'store/slices/call';
import { initialValues, validationSchema } from 'components/client-list';
import {
  responseCommQueue,
  setCurrentTaskClient,
} from 'store/api/action-queue/thunks';
import {
  removeTask,
  updateTask,
  removeTaskForResponse,
} from 'store/api/action-queue';
import {
  selectCurrentTask,
  selectTaskForResponse,
} from 'store/api/action-queue/selectors';
import {
  isMineTask,
  getClientPhonesPayload,
  getClientPhonesInitialValues,
} from 'utils/helpers';

import { useForm } from '../form';
import {
  HangUpDialog,
  ReconnectDialog,
  SubmitCaseDialog,
  ConnectionLostDialog,
} from '../dialogs';

import { useConnectionState } from './connection-states';
import { CallContextProps, SelectClientFormValues } from './types';

export const CallContext = createContext<CallContextProps>(
  {} as CallContextProps
);

export const CallContextProvider = ({
  children,
}: ChildrenProps): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { handleError, showErrorAlert } = useAlert();
  const currentTask = useAppSelector(selectCurrentTask);
  const taskForResponse = useAppSelector(selectTaskForResponse);
  const user = useAppSelector(selectAuthUser);
  const clients = useAppSelector(selectClients);
  const commData = useContext(CommContext);

  const { activeVideoCall } = useSelector((state: RootState) => state.call);

  const removeCurrentTask = async () => {
    if (currentTask) {
      await dispatch(removeTask({ user, task: currentTask }));
    }
  };

  const [updateClient] = useUpdateMutation();

  const isCompletely = useRef<boolean>(false);
  const autoResponseInProgress = useRef<boolean>(false);
  const { activeCall, setActiveCall, setCallbackOpened } = commData;

  const [isHangUpDialogVisible, setHangUpDialogVisible] = useState(false);
  const [isSubmitDialogVisible, setSubmitDialogVisible] = useState(false);
  const [isCallTransferred, setCallTransferred] = useState(false);

  const {
    isLostDialogVisible,
    setLostDialogVisible,
    isReconnectAvailable,
    setReconnectialogVisible,
    isReconnectDialogVisible,
  } = useConnectionState(currentTask);

  useEffect(() => {
    if (!taskForResponse || autoResponseInProgress.current) {
      return;
    }
    const response = async () => {
      try {
        autoResponseInProgress.current = true;
        await dispatch(
          responseCommQueue({
            navigate,
            isLocal: true,
            isContinue: true,
            task: taskForResponse,
            ...commData,
          })
        ).unwrap();
      } catch (error) {
        handleError(error);
        dispatch(removeTaskForResponse());
      } finally {
        autoResponseInProgress.current = false;
      }
    };
    response();
  }, [dispatch, taskForResponse, commData, navigate, handleError]);

  const onReconnect = useCallback(async () => {
    if (!isReconnectAvailable || !currentTask) {
      return;
    }
    try {
      await dispatch(
        responseCommQueue({
          navigate,
          task: currentTask,
          ...commData,
        })
      ).unwrap();
    } catch (error) {
      handleError(error);
    }
  }, [
    dispatch,
    commData,
    navigate,
    handleError,
    currentTask,
    isReconnectAvailable,
  ]);

  const endTask: CallContextProps['endTask'] = useCallback(
    async (data) => {
      try {
        console.log('endTask', JSON.stringify(data), !!currentTask, !!user);
        const { end, caseId, completely } = data || {};
        if (end && currentTask?.CSID) {
          const isMine = isMineTask(currentTask, getUser()?.id);
          if (isMine) {
            console.log('end conference');
            await api.comm.endConference({ CSID: currentTask.CSID });
          } else {
            console.log(
              "don't end conference because this call is not mine",
              currentTask.assignTo
            );
          }
        }
        if (
          currentTask?.channelName &&
          currentTask.type === ActionQueueType.Chat &&
          completely &&
          caseId
        ) {
          await endChat(currentTask.channelName, {
            status: 3,
            case_id: caseId ?? 0,
            end_by: user?.id ?? 0,
            hospital_id: currentTask.h_id ?? 0,
          });
        }
        if (end && currentTask?.channelName) {
          await api.comm.endChat(currentTask.channelName);
        }
        if (activeCall) {
          console.log(
            'call disconnected by end task',
            activeCall.parameters.CallSid
          );
          activeCall.disconnect();
          setActiveCall(undefined);
        }
        const callEndedByUser = end && !completely;
        if (currentTask) {
          if (completely && user) {
            await removeCurrentTask();
          } else if (callEndedByUser) {
            await dispatch(
              updateTask({
                changes: { ...currentTask, $callEndedByUser: true },
              })
            );
            if (currentTask.type === ActionQueueType.Video) {
              await activeVideoCall?.endCall();
              dispatch(setVideoCallEnded(false));
              dispatch(setOnVideoCall(true));
              navigate('/action-center/queue');
            }
          }
        }
      } catch (error) {
        handleError(error);
      }
    },
    [currentTask, user, clients, dispatch, navigate, handleError]
  );

  const onSelectClient = async (values: SelectClientFormValues) => {
    const { selectedClientId } = values;

    if (!selectedClientId) {
      showErrorAlert(Messages.CLIENT_ID_NOT_FOUND);
      return;
    }

    const client = clients?.[selectedClientId];

    if (!client) {
      showErrorAlert(Messages.CLIENT_NOT_FOUND_TO_SELECT);
      return;
    }

    try {
      let taskClient = client;
      console.log('select client');
      if (currentTask?.from) {
        const phonesPayload = getClientPhonesPayload(
          getClientPhonesInitialValues({
            ...taskClient,
            phones: [...(taskClient?.phones ?? []), currentTask.from],
          })
        );

        taskClient = await updateClient({
          ...phonesPayload,
          client_id: selectedClientId,
        }).unwrap();
      } else {
        console.error(
          "can't update client because task w/o from",
          currentTask ? JSON.stringify(currentTask) : null
        );
      }

      await dispatch(
        setCurrentTaskClient({
          client: taskClient,
        })
      ).unwrap();

      navigate(-1);
    } catch (e) {
      handleError(e);
    }
  };

  const form = useForm({
    endTask,
    isCompletely,
    isCallTransferred,
    setCallTransferred,
  });

  const selectClientForm = useFormik<SelectClientFormValues>({
    initialValues,
    validationSchema,
    onSubmit: onSelectClient,
  });

  const value = {
    form,
    endTask,
    activeCall,
    isCompletely,
    selectClientForm,
    isCallTransferred,
    setCallTransferred,
    setHangUpDialogVisible,
    setSubmitDialogVisible,
  };

  const submitCaseAndClose = () => {
    const { isValid, submitForm } = form;
    if (isValid) {
      isCompletely.current = true;
      submitForm();
    } else {
      submitForm();
    }
  };

  const submitCaseForm = (completely: boolean) => {
    isCompletely.current = completely;
    form.submitForm();
  };

  const onHangUp = (submit: boolean) => {
    if (
      currentTask?.type !== ActionQueueType.Voice &&
      currentTask?.type !== ActionQueueType.Video &&
      currentTask?.type !== ActionQueueType.Chat
    ) {
      submitCaseForm(true);
    } else {
      endTask({ end: true });
      if (submit) {
        submitCaseAndClose();
      }
    }
  };

  const onSubmitForm = async (completely: boolean) => {
    if (completely && currentTask?.type === ActionQueueType.Voice) {
      endTask({ end: true });
    }
    submitCaseForm(completely);
  };

  return (
    <CallContext.Provider value={value}>
      {children}

      {isHangUpDialogVisible && currentTask && (
        <HangUpDialog
          task={currentTask}
          onHangUp={onHangUp}
          open={isHangUpDialogVisible}
          setOpen={setHangUpDialogVisible}
        />
      )}

      <SubmitCaseDialog
        onSubmit={onSubmitForm}
        open={isSubmitDialogVisible}
        setOpen={setSubmitDialogVisible}
        isCall={currentTask?.type === ActionQueueType.Voice}
        isVideo={currentTask?.type === ActionQueueType.Video}
      />

      <ConnectionLostDialog
        task={currentTask}
        open={isLostDialogVisible}
        onSubmit={submitCaseAndClose}
        setOpen={setLostDialogVisible}
        onCallback={() => setCallbackOpened(true)}
        onReconnect={
          isReconnectAvailable && isLostDialogVisible ? onReconnect : undefined
        }
      />

      <ReconnectDialog
        onReconnect={onReconnect}
        onSubmit={submitCaseAndClose}
        open={isReconnectDialogVisible}
        setOpen={setReconnectialogVisible}
      />
    </CallContext.Provider>
  );
};
