import { Realtime } from 'ably';
import { useRef, useState, useEffect } from 'react';

export const useAblyEndCall = ({
  roomEvent,
  metaChannelName,
}: {
  roomEvent: string;
  metaChannelName: string;
}) => {
  const [endChat, setEndChat] = useState(false);
  const [endChatChannelName, setEndChatChannelName] = useState('');
  const ablyClient = useRef<Realtime | null>(null);
  const connectionEstablished = useRef(false);

  useEffect(() => {
    if (connectionEstablished.current) {
      return;
    }

    const client = new Realtime({
      logLevel: 1,
      key: process.env.REACT_APP_PUBLIC_ABLY_API_KEY ?? '',
    });

    ablyClient.current = client;

    client.connection.on('connected', () => {
      console.log('Successfully connected to Ably');
    });

    client.connection.on('failed', (err) => {
      console.error('Connection failed:', err);
    });

    const metaChannel = client.channels.get(metaChannelName);

    metaChannel.subscribe(roomEvent, (message) => {
      console.log('meta channel message', message);
      if (message.data?.userData?.channelName) {
        setEndChat(true);
        setEndChatChannelName(message.data?.userData?.channelName);
      }
      if (!message.data.RoomId || !message.data.token) {
        return;
      }
      setEndChat(false);
      setEndChatChannelName('');
    });

    connectionEstablished.current = true;

    return () => {
      if (metaChannel) {
        metaChannel.unsubscribe(roomEvent);
      }
      if (client) {
        client.close();
      }
    };
  }, [metaChannelName, roomEvent]);

  return { endChat, setEndChat, endChatChannelName, setEndChatChannelName };
};
