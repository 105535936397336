import { Utils } from '@gv/triage-components';

import { URL } from '../constants';
import { apiInstance } from '../instance';

export const hospitalList = () => apiInstance.get(URL.GET_HOSPITAL_LIST);

export const getFeedBackList = (filters: any, limit: any, offset: any) =>
  apiInstance.get(
    Utils.Query.addParamsToUrl(URL.GET_CUSTOMER_FEEDBACK_LIST, {
      limit,
      offset,
      filters,
    })
  );

export const sendFeedbackForm = (
  caseId: any,
  feedbackEmail: String,
  callOutcomes: String
) => {
  return apiInstance.post(URL.SEND_TYPE_FORM, {
    callOutcomes,
    feedbackEmail,
    case_form_id: caseId,
  });
};

export const assignTypeForm = (typeformId: any, hospitalID: any) => {
  return apiInstance.post(URL.ASSIGN_TYPE_FORM, {
    type_form_id: typeformId,
    hospital_ids: hospitalID,
  });
};

export const endCallChatVideo = (channelName: string) => {
  return apiInstance.post(URL.END_VIDEO_CHAT, {
    channelName,
  });
};

export const checkTypeFormHospital = (hospitalId: any) =>
  apiInstance.get(`${URL.CHECK_ASSIGN_HOSPITAL}/${hospitalId}`);

export const exportReportData = (filters: any) =>
  apiInstance.get(
    Utils.Query.addParamsToUrl(URL.GET_EXPORT_REPORT, {
      filters,
    }),
    { responseType: 'blob' }
  );
