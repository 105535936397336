import debounce from 'lodash.debounce';
import { Call } from '@twilio/voice-sdk';
import {
  useAlert,
  Messages,
  ChildrenProps,
  CommStatusEnum,
} from '@gv/triage-components';
import {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
} from 'react';

import { RootState, useAppSelector, useAppDispatch } from 'store'; // DON'T SORT, WILL BE BREAK APP
import { useSelector } from 'react-redux';

import { api } from 'api';
import { Config } from 'config';
import { useLazyListQuery } from 'store/api/action-queue';
import { setOnCall, setOnVideoCall } from 'store/slices/call';
import {
  useCallStatus,
  useAblyEventChannel,
  disconnectCallOnUnmount,
} from 'hooks';
import {
  selectAuthUser,
  selectPermissions,
  selectAuthDataForChat,
} from 'store/slices/auth';

import { useAbly } from './ably';
import { useTwilio } from './twilio';
import { Notifier } from './notifier';
import { useAblyChat } from './ably-video';
import { Termination } from './termination';
import { useAblyEndCall } from './ably-end-call';
import { DVMCallProps, CommContextProps } from './types';
import { AutosubmitHandler } from './autosubmit-handler';

const getInitialState = (): CommContextProps => ({
  retry: () => {},
  hasAccess: false,
  setDVMCall: () => {},
  setActiveCall: () => {},
  isCallbackOpened: false,
  setCallbackCall: () => {},
  setCallbackOpened: () => {},
  isTransferMergeOpened: false,
  status: CommStatusEnum.NotReady,
  setTransferMergeOpened: () => {},
});

export const CommContext = createContext<CommContextProps>(getInitialState());

export const CommContextProvider = ({
  children,
}: ChildrenProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const permissions = useAppSelector(selectPermissions);
  const user = useAppSelector(selectAuthUser);
  const { name } = useAppSelector(selectAuthDataForChat);
  const { showErrorAlert } = useAlert();
  const [activeCall, setActiveCall] = useState<Call>();
  const [isCallbackOpened, setCallbackOpened] = useState(false);
  const [dvmCall, setDVMCall] = useState<DVMCallProps | undefined>();
  const [callbackCall, setCallbackCall] = useState<Call | undefined>();
  const [isTransferMergeOpened, setTransferMergeOpened] = useState(false);
  disconnectCallOnUnmount(activeCall);

  const { isOnVideoCall } = useSelector((state: RootState) => state.call);

  const hasCall = !!activeCall;

  useEffect(() => {
    dispatch(setOnCall(hasCall));
  }, [dispatch, hasCall]);

  const {
    ablyError,
    ablyClient,
    chatClient,
    connectAbly,
    destroyAbly,
    ablyChannel,
    isAblyConnected,
  } = useAbly({ userName: name });

  const {
    twilioError,
    twilioDevice,
    connectTwilio,
    destroyTwilio,
    isTwilioSupported,
    isTwilioConnected,
  } = useTwilio(user);

  const commAllowed = useMemo(
    () =>
      !!permissions.communication &&
      Config.portalType.isTeam &&
      isTwilioSupported,
    [permissions.communication]
  );

  const { newChat, setNewChat } = useAblyChat({
    roomEvent: process.env.REACT_APP_ABLY_ROOM_EVENT_KEY ?? '',
    metaChannelName: process.env.REACT_APP_ABLY_META_CHANNEL_NAME_KEY ?? '',
  });

  const { endChat, setEndChat, endChatChannelName, setEndChatChannelName } =
    useAblyEndCall({
      roomEvent: process.env.REACT_APP_PUBLIC_ABLY_END_ROOM ?? '',
      metaChannelName: process.env.REACT_APP_ABLY_META_CHANNEL_NAME_KEY ?? '',
    });

  const [clearQueue, setClearQueue] = useState(false);

  useEffect(() => {
    if (endChat) {
      const endVideoCall = async () => {
        await api.customerFeedback.endCallChatVideo(endChatChannelName);
        setClearQueue(true);
        setEndChat(false);
        setEndChatChannelName('');
      };
      endVideoCall();
    }
  }, [endChat]);

  const callbackCallStatus = useCallStatus(callbackCall);

  const [trigger] = useLazyListQuery();
  const lastRequest = useRef<ReturnType<typeof trigger> | null>(null);
  const deboucneRef = useRef<ReturnType<typeof debounce> | null>(null);

  const refetch = useCallback(() => {
    // lastRequest.current?.abort();
    if (commAllowed) {
      const req = trigger();
      lastRequest.current = req;
    }
    if (newChat || isOnVideoCall) {
      const req = trigger();
      lastRequest.current = req;
      setNewChat(false);
      dispatch(setOnVideoCall(false));
    }
    if (clearQueue) {
      const req = trigger();
      lastRequest.current = req;
      setClearQueue(false);
    }
  }, [trigger, commAllowed, newChat, isOnVideoCall, clearQueue]);

  useEffect(refetch, [refetch]);

  const debounceRefetch = useMemo(() => {
    deboucneRef.current?.cancel();
    const value = debounce(refetch, 1000);
    deboucneRef.current = value;
    return value;
  }, [refetch]);

  useEffect(() => {
    return () => {
      deboucneRef.current?.cancel();
      // lastRequest.current?.abort();
    };
  }, []);

  useAblyEventChannel(
    commAllowed ? 'queue_updated' : undefined,
    debounceRefetch,
    ablyChannel
  );

  const destroy = () => {
    console.log('destroy twilio and ably');
    destroyTwilio();
    destroyAbly();
  };

  const destoryRef = useRef(destroy);

  useEffect(() => {
    destoryRef.current = destroy;
  }, [destroy]);

  const start = () => {
    if (window.navigator) {
      window.navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          // Calling getUserMedia will start the media track selected.
          // This is not desired as the user may get the impression the mic is in use.
          // Therefore, we want to avoid having tracks started when they're not needed.
          // We only wanted to get the input device list so we stop the tracks immediately.
          stream.getTracks().forEach((track) => track.stop());
          connectTwilio();
        })
        .catch(() => {
          // Handle error. Tell the user there's a a mic issue. You could also tell
          // your backend or raise an alert for the system admin to resolve this issue.
          showErrorAlert(Messages.MAKE_SURE_MICROPHONE_PERMISSION);
        });
    }
    connectAbly();
  };

  useEffect(() => {
    return () => {
      destoryRef.current?.();
    };
  }, []);

  useEffect(() => {
    if (commAllowed) {
      start();
    } else {
      destroyTwilio();
      connectAbly();
    }
  }, [commAllowed, user?.name, name]);

  useEffect(() => {
    if (isAblyConnected) {
      debounceRefetch();
    }
  }, [debounceRefetch, isAblyConnected]);

  useEffect(() => {
    if (ablyError && ablyError?.response?.status !== 401) {
      showErrorAlert(ablyError.message);
    } else if (twilioError) {
      showErrorAlert(twilioError.message);
    }
  }, [ablyError, twilioError]);

  const retry = () => {
    console.log('retry');
    if (commAllowed) {
      start();
    } else {
      connectAbly();
    }
  };

  return (
    <CommContext.Provider
      value={{
        retry,
        dvmCall,
        activeCall,
        ablyClient,
        setDVMCall,
        chatClient,
        ablyChannel,
        twilioDevice,
        callbackCall,
        setActiveCall,
        setCallbackCall,
        isCallbackOpened,
        setCallbackOpened,
        callbackCallStatus,
        isTransferMergeOpened,
        setTransferMergeOpened,
        hasAccess: commAllowed,
        status:
          isAblyConnected && isTwilioConnected
            ? CommStatusEnum.Ready
            : CommStatusEnum.NotReady,
      }}
    >
      {children}
      {user && <Notifier user={user} isOnCall={!!activeCall} />}
      {commAllowed && user && <AutosubmitHandler user={user} />}
      <Termination activeCall={activeCall} setActiveCall={setActiveCall} />
    </CommContext.Provider>
  );
};
