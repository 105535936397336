import { useSelector } from 'react-redux';
import { useTyping, useMessages } from '@ably/chat/react';
import { Message, TypingEvent, MessageEvents } from '@ably/chat';
import { formatDate } from '@gv/triage-components/dist/utils/date';
import { useRef, useMemo, useState, useEffect, useContext } from 'react';
import {
  Utils,
  useAlert,
  Messages,
  ChatSection,
  ChatSectionProps,
} from '@gv/triage-components';

import { CommContext } from 'context/comm';
import { parseMessage } from 'utils/helpers';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { useDetailsQuery as useClientQuery } from 'store/api/clients';
import { selectAuthForGVTalk, selectAuthDataForChat } from 'store/slices/auth';
import {
  loadOldMessages,
  sendChatMessage,
} from 'store/slices/chat-messages/thunks';
import {
  addMessage,
  setMessages,
  removeMessage,
  updateMessage,
  selectChatMessages,
} from 'store/slices/chat-messages';

import { ChatProps } from './types';

export const Chat = ({
  task,
  className,
  initialLoadByComponent,
  ...props
}: ChatProps): JSX.Element | null => {
  const [isMessagesLoading, setMessagesLoading] = useState(false);
  const { chatClient } = useContext(CommContext);
  const typingTimeoutRef = useRef<NodeJS.Timeout>();
  const recievingTimeoutRef = useRef<NodeJS.Timeout>();
  const lastTypedRef = useRef<number>(0);
  const TYPING_MESSAGE_ID = 'typing-indicator';
  const TYPING_INTERVAL = 1000; // 1 second

  const { status, channelName } = task ?? {};

  const { handleError, showErrorAlert } = useAlert();

  const dispatch = useAppDispatch();
  const gvTalkAuth = useAppSelector(selectAuthForGVTalk);
  const author = useAppSelector(selectAuthDataForChat);
  const { nextId, messages } = useAppSelector(selectChatMessages(channelName));
  const { get, send } = useMessages();
  const { stop, start, currentlyTyping } = useTyping({
    listener: (typingEvent: TypingEvent) => {
      console.log('Typing event received: ', typingEvent);
    },
  });
  const { data: client } = useClientQuery(
    {
      allowDeleted: true,
      id: String(task?.c_id || ''),
      hospitalId: task?.h_id ? String(task?.h_id) : undefined,
    },
    {
      skip: !task?.c_id,
    }
  );

  const { getPetIDs } = useSelector((state: RootState) => state.call);

  useEffect(() => {
    const typeArray = Array.from(currentlyTyping.values()).filter(
      (item) => item !== author.name
    );

    if (typeArray.length > 0) {
      dispatch(
        addMessage({
          channelName: channelName ?? '',
          message: {
            author_id: 0,
            thread_id: '',
            updated_at: '',
            media_url: null,
            media_type: null,
            isSending: false,
            html: 'typing...',
            id: TYPING_MESSAGE_ID,
            msg_text: 'typing...',
            created_at: new Date().toISOString(),
            state: { author: task?.c_name ?? '' },
            formatting: {
              ops: [{ insert: 'typing...' }],
            },
          },
        })
      );
    } else {
      if (recievingTimeoutRef.current) {
        clearTimeout(recievingTimeoutRef.current);
      }
      recievingTimeoutRef.current = setTimeout(() => {
        dispatch(
          removeMessage({
            id: TYPING_MESSAGE_ID,
            channelName: channelName ?? '',
          })
        );
      }, 100);
    }
  }, [currentlyTyping, recievingTimeoutRef]);

  useEffect(() => {
    if (chatClient && channelName) {
      const channel = chatClient.realtime.channels.get(channelName || '');
      console.log('channel, channel.name', channel, channel.name);
      channel.subscribe('message', (event: any) => {
        console.log('Received message: ', event.message);
      });
      if (status !== 3) {
        channel.publish('Picked-Chat', {
          name: author.name,
          id: author.authorId,
          type: 'Picked-Chat',
          channel_name: channelName,
        });
      }
      channel.subscribe('end-chat', (event: any) => {
        console.log('Received end-chat event: ', event);
        dispatch(
          addMessage({
            channelName: channelName ?? '',
            message: {
              author_id: 0,
              thread_id: '',
              id: 'end-chat',
              updated_at: '',
              media_url: null,
              media_type: null,
              isSending: false,
              state: { author: event.data.name },
              msg_text: 'User has ended the chat',
              created_at: new Date().toISOString(),
              html: `
                <div style="
                  width: 100%;
                  padding: 12px;
                  text-align: center;
                  border-radius: 4px;
                  font-style: italic;
                ">
                  User has ended the chat please submit the case and close the chat
                </div>
              `,
            },
          })
        );
      });
    }

    return () => {
      if (chatClient && channelName) {
        const channel = chatClient.realtime.channels.get(channelName || '');
        channel.unsubscribe('end-chat');
      }
    };
  }, [chatClient, channelName, status]);

  useMessages({
    listener: (event: any) => {
      console.log('Received message: ', event.message);
      switch (event.type) {
        case MessageEvents.Created:
          console.log('Received message: ', event.message);
          dispatch(
            removeMessage({
              id: TYPING_MESSAGE_ID,
              channelName: channelName ?? '',
            })
          );
          setTimeout(() => {
            dispatch(
              addMessage({
                channelName: channelName ?? '',
                message: {
                  thread_id: '',
                  updated_at: '',
                  media_url: null,
                  media_type: null,
                  isSending: false,
                  id: event.message.id,
                  msg_text: event.message.text,
                  created_at: event.message.createdAt,
                  author_id: event.message.metadata.author_id,
                  state: { author: event.message.metadata.sender },
                  formatting: {
                    ops: Utils.Quill.convertHtmlToDelta(event.message.text).ops,
                  },
                  html:
                    event.message.metadata.type === 'file'
                      ? `
                        <div style="
                          padding: 12px;
                          border: 1px solid #E0E0E0;
                          border-radius: 8px;
                          display: flex;
                          align-items: center;
                          gap: 12px;
                        ">
                          <span>${event.message.text.split('/').pop() || 'File'}</span>
                          <a 
                            href="${event.message.text}"
                            target="_blank"
                            style="
                              padding: 8px 16px;
                              background: #0A51C5;
                              color: #FFFFFF;
                              border-radius: 4px;
                              text-decoration: none;
                              font-weight: 500;
                            "
                          >
                            Download
                          </a>
                        </div>
                      `
                      : event.message.text,
                },
              })
            );
          }, 200);

          break;
        case MessageEvents.Updated:
          console.log('Message updated: ', event.message);
          break;
        default:
          break;
      }
    },
  });

  const parsedMessages = useMemo(
    () => messages?.map((msg) => parseMessage(msg)) ?? [],
    [messages?.length]
  );

  useEffect(() => {
    if (!initialLoadByComponent || !channelName) {
      return;
    }
    const load = async () => {
      try {
        setMessagesLoading(true);
        const result: { items: Message[] } = await get({
          limit: 50,
          orderBy: 'oldestFirst',
        });
        const messagesData = result.items.map((item) => ({
          thread_id: '',
          updated_at: '',
          id: item.serial,
          media_url: null,
          media_type: null,
          isSending: false,
          msg_text: item.text,
          created_at: item.createdAt.toISOString(),
          author_id: item.metadata.author_id as number,
          state: { author: item.metadata.sender as string },
          formatting: {
            ops: Utils.Quill.convertHtmlToDelta(item.text).ops,
          },
          html:
            item.metadata.type === 'file'
              ? `
                <div style="
                  padding: 12px;
                  border: 1px solid #E0E0E0;
                  border-radius: 8px;
                  display: flex;
                  align-items: center;
                  gap: 12px;
                ">
                  <span>${item.text.split('/').pop() || 'File'}</span>
                  <a 
                    href="${item.text}"
                    target="_blank"
                    style="
                      padding: 8px 16px;
                      background: #0A51C5;
                      color: #FFFFFF;
                      border-radius: 4px;
                      text-decoration: none;
                      font-weight: 500;
                    "
                  >
                    Download
                  </a>
                </div>
              `
              : item.text,
        }));
        let newMessages: any[] = [];

        if (client?.pets?.length && client?.pets?.length > 0) {
          const petIDs = getPetIDs;
          let petMessages = [];
          for (let pet of client?.pets) {
            if (petIDs.includes(pet.id)) {
              petMessages.push({
                id: '0',
                msg_text: '',
                author_id: 0,
                thread_id: '',
                updated_at: '',
                media_url: null,
                media_type: null,
                isSending: false,
                formatting: { ops: [] },
                state: { author: task?.c_name ?? '' },
                created_at:
                  result.items.length > 0
                    ? result.items[0].createdAt.toISOString()
                    : new Date().toISOString(),
                html: `
              <h4>${task?.from}</h4>
              <p style="margin-bottom: 16px;">Please go through and review the details shared</p>
              <div style="
                padding: 16px;
                border: 1px solid #E0E0E0;
                border-radius: 8px;
              ">
                <h3>Your pet's information</h3>
                <div style="
                  border-radius: 8px;  
                  padding: 16px;
                  padding-left: 0px;
                  display: flex;
                  gap: 16px;
                ">
                  <div>
                    <h4>Pet Name</h4>
                    <p>${pet?.name}</p>
                  </div>
                  <div>
                    <h4>Species</h4>
                    <p>${pet?.species}</p>
                  </div>
                </div>
                <div style="
                  padding: 16px;
                  display: flex;
                  padding-left: 0px;
                  gap: 16px;
                ">
                  <div>
                    <h4>Gender</h4>
                    <p>${pet?.gender}</p>
                  </div>
                </div>
                <div style="
                  padding: 16px;
                  display: flex;
                  padding-left: 0px;
                  gap: 16px;
                ">
                  <div>
                    <h4>Date of Birth</h4>
                    <p>${pet?.date_of_birth ? formatDate(pet?.date_of_birth, 'MMM DD, YYYY') : 'Not Provided'}</p>
                  </div>
                </div>
                <div style="
                  padding: 16px;
                  display: flex;
                  padding-left: 0px;
                  gap: 16px;
                ">
                  <div>
                    <h4>Adoption Date</h4>
                    <p>${pet?.adoption_date ? formatDate(pet?.adoption_date, 'MMM DD, YYYY') : 'Not Provided'}</p>
                  </div>
                </div>
                <div style="
                  padding: 16px;
                  display: flex;
                  padding-left: 0px;
                  gap: 16px;
                ">
                  <div>
                    <h4>Medical Records</h4>
                    ${
                      pet?.medical_records
                        ? `<a
                        target="_blank"
                        href="${pet.medical_records}"
                        rel="noopener noreferrer"
                      >
                        Medical Record
                      </a>`
                        : '<div>No records available</div>'
                    }
                  </div>
                </div>
              </div> 
              `,
              });
            }

            newMessages = [...petMessages, ...messagesData];
          }
        } else {
          newMessages = messagesData;
        }
        dispatch(
          setMessages({
            channelName,
            data: {
              messages: newMessages,
            },
          })
        );
      } catch (error) {
        handleError(error);
      } finally {
        setMessagesLoading(false);
      }
    };
    load();
  }, [channelName, initialLoadByComponent, client]);

  const sendMessage = async ({
    id,
    ...rest
  }: {
    id: string | number;
  } & Parameters<typeof sendChatMessage>[0]) => {
    if (!channelName) {
      showErrorAlert(Messages.NO_TASK);
      return;
    }

    try {
      await dispatch(sendChatMessage(rest)).unwrap();

      dispatch(
        removeMessage({
          id,
          channelName,
        })
      );
    } catch (error) {
      dispatch(
        updateMessage({
          id,
          channelName,
          isError: true,
          isSending: false,
        })
      );

      handleError(error);
    }
  };

  const handleSendMessage: ChatSectionProps['handleSendMessage'] = async (
    values,
    resetForm
  ) => {
    if (!channelName) {
      showErrorAlert(Messages.NO_TASK);
      return;
    }
    try {
      await send({
        text: values.message,
        metadata: {
          sender: author.name,
          author_id: author.authorId,
          time: new Date().toLocaleTimeString(),
        },
      });
    } finally {
      resetForm();
    }
  };

  const onTopReached = async () => {
    if (channelName && nextId) {
      try {
        setMessagesLoading(true);
        await dispatch(loadOldMessages({ nextId, channelName })).unwrap();
      } catch (error) {
        handleError(error);
      } finally {
        setMessagesLoading(false);
      }
    }
  };

  const onRetry: ChatSectionProps['onRetry'] = async ({
    id,
    msg_text,
    ...rest
  }) => {
    if (!channelName) {
      showErrorAlert(Messages.NO_TASK);
      return;
    }

    dispatch(
      updateMessage({
        id,
        channelName,
        isError: false,
        isSending: true,
      })
    );

    await sendMessage({ id, message: msg_text, ...rest });
  };

  return (
    <ChatSection
      task={task}
      onRetry={onRetry}
      className={className}
      parseOptions={gvTalkAuth}
      messages={parsedMessages}
      isLoading={isMessagesLoading}
      handleSendMessage={handleSendMessage}
      onTopReached={nextId ? onTopReached : undefined}
      onType={() => {
        const now = Date.now();
        if (now - lastTypedRef.current > TYPING_INTERVAL) {
          start();
          lastTypedRef.current = now;
          // Stop typing indicator after 2 seconds of no typing
          if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
          }
          typingTimeoutRef.current = setTimeout(() => {
            stop();
          }, 2000);
        }
      }}
      {...props}
    />
  );
};
